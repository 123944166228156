import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import HomeContent from "./Screens/HomeContent";
import ToursList from "./Screens/ToursList";
import TourDetails from "./Screens/TourDetails";
import Gallery from "./Screens/Gallery";
import AboutUs from "./Screens/AboutUs";
import ContactUs from "./Screens/ContactUs";
import PaymentSummary from "./Screens/PaymentSummary";
import ErrorPage from "./Screens/ErrorPage";
import Bus from "./Screens/Bus";
import Flight from "./Screens/Flight";
import PaymentSuccess from "./Screens/PaymentSuccess";
import PaymentFailure from "./Screens/PaymentFailure";
import FeedBack from "./Screens/FeedBack";
import TermAndConditions from "./Screens/TermAndConditions";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import RefundPolicy from "./Screens/RefundPolicy";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <HomeContent />
              </Layout>
            }
          />

          <Route
            path="/tour-list"
            element={
              <Layout>
                <ToursList />
              </Layout>
            }
          />

          <Route
            path="/tour-details/:id"
            element={
              <Layout>
                <TourDetails />
              </Layout>
            }
          />

          <Route
            path="/gallery"
            element={
              <Layout>
                <Gallery />
              </Layout>
            }
          />

          <Route
            path="/about-us"
            element={
              <Layout>
                <AboutUs />
              </Layout>
            }
          />

          <Route
            path="/bus"
            element={
              <Layout>
                <Bus />
              </Layout>
            }
          />

          <Route
            path="/flight"
            element={
              <Layout>
                <Flight />
              </Layout>
            }
          />

          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />

          <Route
            path="/feedback"
            element={
              <Layout>
                <FeedBack />
              </Layout>
            }
          />

          <Route
            path="/refund-policy"
            element={
              <Layout>
                <RefundPolicy />
              </Layout>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          
          <Route
            path="/terms-and-conditions"
            element={
              <Layout>
                <TermAndConditions />
              </Layout>
            }
          />

          <Route path="/paymentSummary" element={<PaymentSummary />} />
          <Route path="/paymentSuccess" element={<PaymentSuccess />} />
          <Route path="/paymentFailure" element={<PaymentFailure />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
