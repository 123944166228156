import React from "react";
import { useNavigate } from "react-router-dom";
const PaymentFailure = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="flex justify-center items-center mt-[50vh]">
      <div className="col-md-6 text-center">
        <div className="alert alert-danger text-center">
          <h4 className="alert-heading">Oops, something went wrong!</h4>
        </div>
        <button className="bg-red-500 p-2" onClick={handleClick}>Back to Home</button>
      </div>
    </div>
  );
};

export default PaymentFailure; // Export as default
