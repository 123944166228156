import React, { useState, useEffect } from "react";
import PaymentsForm from "../Components/PaymentsForm";
import BookingDetails from "../Components/BookingDetails";
import { useParams } from "react-router-dom";


const PaymentSummary = () => {
  const [packageData, setPackageData] = useState({});
  const [userData, setUserData] = useState({});
  const { id } = useParams();
console.log("order_id",id)

  useEffect(() => {
    setPackageData(JSON.parse(localStorage.getItem("packageData")) || {});
    setUserData(JSON.parse(localStorage.getItem("userData")) || {});
  }, []);

  console.log("packageData", packageData);
  // console.log("userData", userData);
  return (
    <React.Fragment>
      <div class="relative mx-auto w-full bg-white">
        <div class="grid min-h-screen grid-cols-10">
          <BookingDetails packageData={packageData} userData={userData} />
          <PaymentsForm packageData={packageData} userData={userData} order_id={id} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentSummary;
