import React from 'react';

const Hotels = ({ tourDetailsData }) => {
  return (
    <div class="mx-auto">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr className='rounded-t-md'>
            <th class="py-2 px-4 bg-gray-100 border border-gray-300">Nights</th>
            <th class="py-2 px-4 bg-gray-100 border border-gray-300">Place</th>
            <th class="py-2 px-4 bg-gray-100 border border-gray-300">Hotel Name (Standard)</th>
            <th class="py-2 px-4 bg-gray-100 border border-gray-300">Hotel Name (Deluxe)</th>
          </tr>
        </thead>
        <tbody>
          {
            tourDetailsData?.hotel.map((ele) =>
              <tr>
                <td class="py-2 px-4 border border-gray-300">{ele.nights}</td>
                <td class="py-2 px-4 border border-gray-300">{ele.place}</td>
                <td class="py-2 px-4 border border-gray-300">{ele.hotel_name_standard}</td>
                <td class="py-2 px-4 border border-gray-300">{ele.hotel_name_deluxe}</td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  )
}

export default Hotels
