import React from 'react'
import CommingSoon from '../Components/CommingSoon'

const Flight = () => {
  return (
    <React.Fragment>
      <CommingSoon />
    </React.Fragment>
  )
}

export default Flight
