import React from 'react';
import './ErrorPage.css';
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  document.title = 'Weekend Bhraman | 404-Error';
  let navigate = useNavigate();

  const goToHome = () => {
    navigate('/')
  }
  return (
    <React.Fragment>
      <div className="mx-auto mt-12">
        <div className="flex justify-center">
          <div className="w-full ">
            <div className="text-center">
              <div className="four_zero_four_bg bg-[#ffdcc7]">
                <h1 style={{ fontFamily: 'cursive' }} className="text-center font-bold text-[#d4531c] text-6xl tracking-wide">404</h1>
              </div>

              <div className="mt-[-40px]">
                <h3 style={{ fontFamily: 'cursive' }} className="text-[#051836] text-3xl font-bold tracking-wider">
                  Look like you're lost.
                </h3>

                <p className="text-[#051836] mt-4 font-semibold tracking-wide">
                  The page you are looking for is not available!
                </p>

                <button
                  onClick={goToHome}
                  className="tracking-wide font-semibold mt-4 sm:mt-6 md:mt-8 w-full md:w-4/12 px-6 py-2 text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg"
                >
                  Go to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ErrorPage
