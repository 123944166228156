import React from 'react'
import { GoDotFill } from 'react-icons/go'
import PrivacyImage from "../Assets/PrivacyAndPolicy.png"
import { FaRegHandPointRight } from "react-icons/fa6";

export default function PrivacyPolicy() {
    return (
        <div>
            <React.Fragment>
                <div className="flex-grow relative mb-8">
                    <div className="relative">
                        <img className="w-full h-auto" src={PrivacyImage} alt="Tourimg" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white md:text-4xl text-2xl font-semibold">
                            Privacy & Pricing Policy
                        </div>
                    </div>
                </div>
            </React.Fragment>

            <div className="container mx-auto p-8">
                <div>
                    <div className='flex items-center'>
                        <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mb-4'>
                            Privacy & Pricing Policy
                        </div>
                    </div>

                    <p className="text-[#051836] leading-relaxed text-justify">Welcome to <b className='text-blue-500'>www.weekendbhraman.com</b> Tour Planner! This Privacy Policy explains how we collect, use, and safeguard your personal information. By using our website, you agree to the terms outlined in this policy. </p>
                </div>

                <React.Fragment>


                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5 mb-3'>
                        Information We Collect:
                    </div>
                    <>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <GoDotFill size={15} color='#ff783e' />
                            </div>
                            <p className="font-medium ml-2">Personal Information:</p>
                        </div>
                        <div className='ml-6'>
                            <p className='text-[#051836]'> When you make a booking or create an account, we may collect personal information such as your name, address, email, phone number, and payment details.</p>
                        </div>
                    </>
                    <>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <GoDotFill size={15} color='#ff783e' />
                            </div>
                            <p className="font-medium ml-2">Booking Information:</p>
                        </div>
                        <div className='ml-6'>
                            <p className='text-[#051836]'>  We collect information about your travel bookings, including destination, dates, accommodation, and activities.</p>
                        </div>
                    </>
                    <>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <GoDotFill size={15} color='#ff783e' />
                            </div>
                            <p className="font-medium ml-2">Device Information:</p>
                        </div>
                        <div className='ml-6'>
                            <p className='text-[#051836]'>We may collect information about the device you use to access our website, including IP address, browser type, and operating system.</p>
                        </div>
                    </>

                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        How We Use Your Information
                    </div>
                    <p className='text-gray-400'>(We use the collected information for the following purposes)</p>

                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">To process and fulfill your travel bookings.</p>
                    </div>

                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">To communicate with you about your bookings, updates, and promotions.</p>
                    </div>

                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">To improve our website, services, and customer experience.</p>
                    </div>

                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">To analyze trends and gather demographic information.</p>
                    </div>

                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        Sharing Your Information
                    </div>
                    <p className='text-gray-400'>(We may share your information with third parties in the following situations)</p>

                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">With service providers and partners involved in fulfilling your travel bookings.
                            In response to legal requests or to protect our rights and interests.
                            With your consent.</p>
                    </div>

                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        Cookies and Tracking
                    </div>
                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className="text-[#051836] ml-2">We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</p>
                    </div>
                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        Security
                    </div>
                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>
                    </div>

                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        Your Choices
                    </div>
                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">You have the right to access, correct, or delete your personal information. You can manage your communication preferences and opt-out of promotional emails.</p>
                    </div>

                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        Changes to this Privacy Policy
                    </div>
                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">We may update this Privacy Policy periodically.</p>
                    </div>

                    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mt-5'>
                        Contact Us:
                    </div>
                    <div className='flex justify-start items-center mt-2'>
                        <div>
                            <GoDotFill size={15} color='#ff783e' />
                        </div>
                        <p className=" text-[#051836] ml-2">If you have questions or concerns about this Privacy Policy, please contact us at:</p>
                    </div>
                    <div className='ml-5'>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <FaRegHandPointRight size={15} color='#ff783e' />
                            </div>
                            <p className=" text-[#051836] ml-2">Head Office : A-37 Sachdeva Complex, Madhu Vihar I.P Ext. New Delhi-92</p>
                        </div>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <FaRegHandPointRight size={15} color='#ff783e' />
                            </div>
                            <p className=" text-[#051836] ml-2">Branch Office : Haridwar: Dudhadhari Chowk Near Rajput, Dharmsala, Bhupatwala, Haridwar-249410</p>
                        </div>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <FaRegHandPointRight size={15} color='#ff783e' />
                            </div>
                            <p className=" text-[#051836] ml-2">Info@weekendbhraman.com</p>
                        </div>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <FaRegHandPointRight size={15} color='#ff783e' />
                            </div>
                            <p className=" text-[#051836] ml-2"> +91-8178958591 </p>
                        </div>
                        <div className='flex justify-start items-center mt-2'>
                            <div>
                                <FaRegHandPointRight size={15} color="#ff783e" />
                            </div>
                            <p className=" text-[#051836] ml-2">+91-9310366002</p>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </div>
    )
}
