import React from "react";
import "./HomeContent.css";
import SatisfiedClient from "../Components/SatisfiedClient";
import ExperienceCard from "../Components/ExperienceCard";
import WhyChooseUsCard from "../Components/WhyChooseUsCard";
import InternationalToursPackages from "../Components/InternationalToursPackages";
import HolidayCategories from "../Components/HolidayCategories";
import TourCategories from "../Components/TourCategories";
import Carousel from "../Components/Carousel";
import AllToursList from "../Components/AllToursList";

const HomeContent = () => {
  document.title = "Weekend Bhraman Tour Planner";

  return (
    <React.Fragment>
      <Carousel />

      <React.Fragment>
        <div className="flex flex-col items-center justify-center mt-6">
          <div
            style={{ fontFamily: "cursive" }}
            className="text-[#ff783e] text-center w-full"
          >
            Sector we covered
          </div>

          <div className="text-[#051836] text-2xl font-bold text-center w-full mt-2">
            Exclusive Spiritual Tours
          </div>
        </div>

        <AllToursList />
      </React.Fragment>

      <TourCategories />
      <ExperienceCard />
      <HolidayCategories />

      <InternationalToursPackages />
      <WhyChooseUsCard />
      <SatisfiedClient />
    </React.Fragment>
  );
};

export default HomeContent;
