import React, { useState, useEffect } from "react";
import kedarnath from "../Assets/kedarnath.jpg";
import Details from "../Components/Details";
import Inclusion from "../Components/Inclusion";
import TAndC from "../Components/TAndC";
import Hotels from "../Components/Hotels";
import TourBookForm from "../Components/TourBookForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PackageCategoryDropDown } from "../Components/PackageCategoryDropDown";

const TourDetails = () => {
  document.title = "Weekend Bhraman | Tour Details";
  const [activeTab, setActiveTab] = useState(1);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [tourDetailsData, setTourDetailsData] = useState({});
  const [tourCategoryData, setTourCategoryData] = useState([]);
  const [SelectedPackageCategory, setSelectedPackageCategory] = useState("");
  let apiUrl = process.env.REACT_APP_LIVE_URL;
  const { id } = useParams();

  const changeTab = (tab) => {
    setActiveTab(tab);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  localStorage.setItem(
    "packageData",
    JSON.stringify({
      packageCategory: SelectedPackageCategory,
      package: tourDetailsData,
    })
  );
  localStorage.removeItem("userData");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (apiUrl) {
          const config1 = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${apiUrl}/package/packageAllDataByPackageId?id=${id}`,
            headers: {},
          };

          const config2 = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${apiUrl}/packageCategory/getAll`,
            headers: {},
          };

          const [response1, response2] = await Promise.all([
            axios.request(config1),
            axios.request(config2),
          ]);

          setTourDetailsData(response1?.data?.package);
          function filterData(location, packageName) {
            return response2?.data?.packageCategory.filter(
              (item) =>
                item.location === location && item.packageName === packageName
            );
          }
          const locationToFilter = response1?.data?.package?.arrival_city;
          const packageNameToFilter = response1?.data?.package?.name;
          const filteredData = filterData(
            locationToFilter,
            packageNameToFilter
          );
          if (filteredData) {
            setTourCategoryData(filteredData);
          } else {
            console.log("Unable to get data");
          }
        } else {
          console.log("Unable to get data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [apiUrl, id]);

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="flex flex-col sm:flex-row items-center justify-between py-2 px-8">
          {!tourDetailsData._id ? (
            <>
              {[0].map((index) => {
                return (
                  <div key={index} className="min-w-72 relative rounded animate-pulse transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105">
                    <div className="text-lg font-semibold mb-2 bg-gray-300 h-5 w-2/3"></div>
                    <div className="flex justify-start items-center">
                      <div className="text-sm mb-2 text-gray-500 bg-gray-300 h-4 w-2/3"></div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="flex flex-col mb-2 sm:mb-0 sm:mr-4">
                <h2 className="text-xl font-bold tracking-wide text-[#051836]">
                  {tourDetailsData?.name}
                </h2>
                <p className="text-md text-[#8592A6] text-center sm:text-start">
                  Departure from{" "}
                  <span className="text-[#ff783e] tracking-wide font-bold">
                    {tourDetailsData?.arrival_city}
                  </span>
                </p>
              </div>
            </>
          )}

          <PackageCategoryDropDown
            tourCategoryData={tourCategoryData}
            setSelectedPackageCategory={setSelectedPackageCategory}
          />
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="w-full">
          <img src={tourDetailsData?.packageDetail_id?.image_url_object?.big_image} alt="img" className="w-full max-h-96" />
        </div>

        {/* <div className="flex w-full p-2 gap-2 overflow-x-auto catagories-container">
          {
            tourDetailsData?.packageDetail_id?.image_url_object?.small_image.map((ele, ind) =>
              <img key={ind} src={ele} className="max-w-[20%] h-auto" alt="small-imgs" />
            )
          }
        </div> */}
      </React.Fragment>

      <React.Fragment>
        <div className="pl-4 pt-4 rounded-md block sm:hidden">
          <div className="flex rounded-md relative justify-end">
            <button
              className=" mr-4 py-2 px-8 rounded-md bg-[#ff783e] text-white"
              onClick={toggleDropdown}
            >
              {activeTab === 1
                ? "Details"
                : activeTab === 2
                  ? "Inclusion"
                  : activeTab === 3
                    ? "T & C"
                    : "Hotels"}
            </button>

            {isDropdownOpen && (
              <div className="absolute top-full right-0 mt-2 bg-white rounded-md shadow-lg mr-4">
                {[1, 2, 3, 4].map((tab) => (
                  <button
                    key={tab}
                    className={`block w-full py-2 px-4 text-left ${activeTab === tab
                      ? "bg-[#ff783e] text-white"
                      : "bg-[#EBEBEB] text-gray-800"
                      }`}
                    onClick={() => changeTab(tab)}
                  >
                    {tab === 1
                      ? "Details"
                      : tab === 2
                        ? "Inclusion"
                        : tab === 3
                          ? "T & C"
                          : "Hotels"}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="pl-4 pt-4 rounded-md hidden sm:block">
          <div className="flex rounded-md">
            <button
              className={`py-2 px-8 rounded-l-md ${activeTab === 1
                ? "bg-[#ff783e] text-white"
                : "bg-[#EBEBEB] text-gray-800"
                }`}
              onClick={() => changeTab(1)}
            >
              Details
            </button>
            <button
              className={`py-2 px-8 ${activeTab === 2
                ? "bg-[#ff783e] text-white"
                : "bg-[#EBEBEB] text-gray-800"
                }`}
              onClick={() => changeTab(2)}
            >
              Inclusion
            </button>
            <button
              className={`py-2 px-8 ${activeTab === 3
                ? "bg-[#ff783e] text-white"
                : "bg-[#EBEBEB] text-gray-800"
                }`}
              onClick={() => changeTab(3)}
            >
              T & C
            </button>
            <button
              className={`py-2 px-8 rounded-r-md ${activeTab === 4
                ? "bg-[#ff783e] text-white"
                : "bg-[#EBEBEB] text-gray-800"
                }`}
              onClick={() => changeTab(4)}
            >
              Hotels
            </button>
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-[60%] w-[100%] p-4 text-[#051836]">
            {activeTab === 1 && <Details tourDetailsData={tourDetailsData} />}

            {activeTab === 2 && <Inclusion tourDetailsData={tourDetailsData} />}

            {activeTab === 3 && <TAndC tourDetailsData={tourDetailsData} />}

            {activeTab === 4 && <Hotels tourDetailsData={tourDetailsData} />}
          </div>

          <TourBookForm />
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default TourDetails;
