import React, { useEffect, useState } from 'react';
import Footer from './Screens/Footer';
import Navbar from './Screens/Navbar';
import { FaWhatsapp } from "react-icons/fa6";
import { LuPhoneCall } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import GetInTouchModal from './Components/Modals/GetInTouchModal';

const Layout = ({ children }) => {
    const [isHovered, setHovered] = useState(false);
    const navigate = useNavigate()

    const [openGetInTouchModal, setOpenGetInTouchModal] = useState(false);

    const MessageButton = () => {
        const openMessageModal = () => {
            window.open("https://web.whatsapp.com/", "_blank");
        };

        return (
            <button
                className="fixed right-4 bottom-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-300 hover:scale-105 ease-in-out hover:duration-300 focus:outline-none transition-transform transform-gpu"
                onClick={openMessageModal}
            >
                <FaWhatsapp size={32} color='#10b416' />
            </button>
        );
    };

    const GetInTouchButton = () => {

        return (
            <div className="fixed left-4 bottom-4">
                <div
                    onClick={() => setOpenGetInTouchModal(true)}
                    className="bg-[#f0752c] p-3 text-white cursor-pointer rounded-full flex items-center"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <LuPhoneCall size={26} color='white' />
                </div>

                {isHovered && (
                    <div className="absolute font-semibold w-28 text-center bottom-0 left-32 transform -translate-x-1/2 px-2 py-3 bg-gray-400 text-white rounded shadow">
                        Get in touch
                    </div>
                )}
            </div>

        );
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            setOpenGetInTouchModal(true);
        }, 5000);
        return () => clearTimeout(timerId);
    }, []);


    return (
        <React.Fragment>
            <GetInTouchModal
                openGetInTouchModal={openGetInTouchModal}
                setOpenGetInTouchModal={setOpenGetInTouchModal}
            />
            <Navbar />
            <React.Fragment>
                {children}
                <GetInTouchButton />
                <MessageButton />
            </React.Fragment>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;
