import React from "react";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { MdOutlineLocationCity } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";
import { MdTour } from "react-icons/md";
import { GiCheckMark } from "react-icons/gi";
import { FaChildren } from "react-icons/fa6";

const BookingDetails = ({ userData, packageData }) => {
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    if (isNaN(dateObject.getTime())) {
      return "Invalid Date";
    }
    return `${String(dateObject.getDate()).padStart(2, "0")}/${String(
      dateObject.getMonth() + 1
    ).padStart(2, "0")}/${dateObject.getFullYear()}`;
  };

  return (
    <React.Fragment>
      {/* <Razorpay orderId={orderId} /> */}

      <div class="relative col-span-full flex flex-col py-6 pl-8 pr-4 lg:col-span-5">
        <div>
          <img
            src="https://e0.pxfuel.com/wallpapers/453/232/desktop-wallpaper-kedarnath-temple-thumbnail.jpg"
            alt=""
            class="absolute inset-0 h-full w-full object-cover"
          />
          <div class="absolute inset-0 h-full w-full bg-gradient-to-tr from-[#30aadd] via-[#507584] to-[#30aadd] opacity-95"></div>
        </div>

        <div class="relative">
          <h1 class="relative text-2xl font-medium text-white sm:text-3xl">
            Customer Details
            <span class="mt-2 block h-1 w-full bg-white mb-4"></span>
          </h1>

          <ul class="space-y-5">
            <li class="flex justify-between">
              <div class="w-full">
                <div class="flex items-between">
                  <div className="">
                    <IoIosPerson size={34} color="#000" />
                  </div>
                  <p class="text-lg capitalize font-bold text-white ml-2">
                    {userData?.name}
                  </p>
                </div>
                <span class="mt-1 block h-0.5 w-full md:w-1/2 bg-white mb-4"></span>

                <div class="grid grid-cols-10 w-full">
                  <div class="md:col-span-5 col-span-full">
                    <div class="mt-2 flex items-between">
                      <MdEmail size={18} color="black" />
                      <div class="text-md font-medium text-white text-opacity-80 ml-2">
                        {userData?.email}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <IoMdCall size={18} color="black" />
                      <div class="text-md font-medium text-white text-opacity-80 ml-2">
                        {userData?.number}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <MdOutlineLocationCity size={18} color="black" />
                      <div class="text-md font-medium text-white text-opacity-80 ml-2">
                        Departure date -{" "}
                        {userData?.departureDate &&
                          formatDate(userData.departureDate)}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <MdOutlineLocationCity size={18} color="black" />
                      <div class="text-md capitalize font-medium text-white text-opacity-80 ml-2">
                        Deparature city - {userData?.expected_deparature_city}
                      </div>
                    </div>
                  </div>

                  <div class="md:col-span-5 col-span-full">
                    <div class="mt-2 flex items-between">
                      <MdEmail size={18} color="black" />
                      <div class="text-md font-medium text-white text-opacity-80 ml-2">
                        Number of Adults - {userData?.adultsCount}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <FaChildren size={18} color="black" />
                      <div class="text-md font-medium text-white text-opacity-80 ml-2">
                        Number of Childrens - {userData?.childrenCount}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <FaChildren size={18} color="black" />
                      <div class="text-md font-medium text-white text-opacity-80 ml-2">
                        Total members -{" "}
                        {userData?.adultsCount + userData?.childrenCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <h1 class="relative text-2xl font-medium text-white sm:text-3xl mt-4">
            Package Details
            <span class="mt-2 block h-1 w-full bg-white mb-4"></span>
          </h1>

          <ul class="space-y-5">
            <li class="flex justify-between">
              <div class="w-full">
                <div class="flex items-between">
                  <div className="">
                    <MdTour size={34} color="#000" />
                  </div>
                  <p class="text-lg font-bold uppercase text-white ml-2">
                    {packageData?.package?.name}
                  </p>
                </div>
                <span class="mt-1 block h-0.5 w-full md:w-1/2 bg-white mb-4"></span>

                <div class="grid grid-cols-10 w-full">
                  <div class="md:col-span-5 col-span-full">
                    <div class="mt-2 flex items-between">
                      <GiCheckMark size={18} color="black" />
                      <div class="text-md capitalize font-medium text-white text-opacity-80 ml-2">
                        Type of Package - {packageData?.packageCategory?.type}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <GiCheckMark size={18} color="black" />
                      <div class="text-md capitalize font-medium text-white text-opacity-80 ml-2">
                        Deparature Time - {packageData?.package?.departure_time}
                      </div>
                    </div>
                  </div>

                  <div class="md:col-span-5 col-span-full">
                    <div class="mt-2 flex items-between">
                      <GiCheckMark size={18} color="black" />
                      <div class="text-md capitalize font-medium text-white text-opacity-80 ml-2">
                        {packageData?.package?.meals}
                      </div>
                    </div>

                    <div class="mt-2 flex items-between">
                      <GiCheckMark size={18} color="black" />
                      <div class="text-md capitalize font-medium text-white text-opacity-80 ml-2">
                        {packageData?.package?.tour_duration}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div class="my-4 h-0.5 w-full bg-white bg-opacity-30"></div>
        </div>

        <div class="flex justify-center w-full items-center">
          <div class="relative text-white text-center">
            <h3 class="mb-2 text-lg font-bold">Support</h3>
            <p class="text-sm font-semibold">
              +91-8178958591, +91-9310366002
            </p>
            <p class="text-sm font-semibold">
              +91-9540512487, +91-8076663076  <span>(if urgent)</span>
            </p>
            <p class="mt-1 text-sm mailto:font-semibold">
              Info@weekendbhraman.com
            </p>
            <p class="mt-2 text-xs font-medium">
              Call us now for payment related issues
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BookingDetails;
