import React from 'react';
import whyChooseUs from "../Assets/why_choose_us_pic.webp"

const WhyChooseUsCard = () => {
  return (
    <React.Fragment>
      <React.Fragment>
                <div className='bg-[#f7fffe] w-full py-2'>
                    <div className='text-[#051836] text-2xl mr-2 font-bold text-center w-full mt-6'>
                        Why Choose Us?
                    </div>

                    <div className='text-[#8592A6] mr-2 text-center w-full'>
                        The Reasons why you need should book with us ?
                    </div>

                    <div className="w-full flex justify-center items-center">
                        <img
                            src={whyChooseUs}
                            alt="img"
                            className="w-[85%] h-auto"
                        />
                    </div>
                </div>
            </React.Fragment>
    </React.Fragment>
  )
}

export default WhyChooseUsCard
