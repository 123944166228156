import React from "react";
import { FaCheck } from "react-icons/fa6";
import { RxStopwatch } from "react-icons/rx";
import { IoFastFoodOutline } from "react-icons/io5";
import { PiSteeringWheelDuotone } from "react-icons/pi";
import { LuCalendarDays } from "react-icons/lu";

const Details = ({ tourDetailsData }) => {
  const TourDetailsSkeleton = () => (
    <div className="animate-pulse p-4">
      <div className="bg-gray-200 h-6 w-2/3 mb-4"></div>
      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      <div className="mb-4">
        <div className="bg-gray-200 h-4 w-1/3 mb-2"></div>
        <div className="bg-gray-200 h-4 w-1/2"></div>
      </div>

      {/* ... Add more skeleton elements as needed ... */}
    </div>
  );
  return (
    <>
      {tourDetailsData && (
        <React.Fragment>
          <React.Fragment>
            {!tourDetailsData?._id && <TourDetailsSkeleton />}

            <div className="flex flex-wrap mb-4">
              {tourDetailsData?._id && (
                <>
                  <div className="flex items-center w-[180px] pl-2 mt-2 border p-4 rounded-t-md sm:rounded-l-md sm:rounded-t-none">
                    <RxStopwatch size={30} color="#30aadd" />
                    <p className="ml-4 sm:ml-6 font-semibold tracking-wider">
                      {tourDetailsData?.tour_duration}
                    </p>
                  </div>

                  <div className="flex items-center w-[180px] pl-2 mt-2 border p-4 sm:border-l-0">
                    <IoFastFoodOutline size={30} color="#30aadd" />
                    <p className="ml-4 sm:ml-6 font-semibold tracking-wider">
                      {tourDetailsData?.meals}
                    </p>
                  </div>

                  <div className="flex items-center w-[180px] pl-2 mt-2 border p-4 sm:border-l-0">
                    <PiSteeringWheelDuotone size={30} color="#30aadd" />
                    <p className="ml-4 sm:ml-6 font-semibold tracking-wider">
                      {tourDetailsData?.travel_by}
                    </p>
                  </div>
                </>
              )}
            </div>

            <div className="border rounded-md p-4">
              <h2 className="text-lg font-bold text-[#051836]">
                {tourDetailsData?.name}
              </h2>

              <p className="text-sm text-[#8592A6] mt-2">
                {tourDetailsData?.activities}
              </p>

              {tourDetailsData?.return_location && (
                <>
                  <div className="flex justify-between items-center my-2">
                    <div className="text-md font-semibold mb-2">
                      Deparature/Return Location
                    </div>
                    <p className="text-sm text-[#8592A6]">
                      {tourDetailsData?.return_location}
                    </p>
                  </div>

                  <div className="w-full h-px bg-gray-300 mb-2"></div>
                </>
              )}

              {tourDetailsData?.departure_time && (
                <>
                  <div className="flex justify-between items-center my-2">
                    <div className="text-md font-semibold mb-2">
                      Deparature Time
                    </div>
                    <p className="text-sm text-[#8592A6]">
                      {tourDetailsData?.departure_time}
                    </p>
                  </div>
                  <div className="w-full h-px bg-gray-300 mb-2"></div>
                </>
              )}

              {tourDetailsData?.things_to_carry && (
                <>
                  <div className="flex flex-col md:flex-row justify-between items-center mt-4 mb-2 bg-gray-200 rounded-md p-4">
                    <div className="text-md font-bold mb-2 w-full md:w-1/3 text-center text-[#ff783e]">
                      Things to carry
                    </div>

                    <div className=" grid grid-cols-2 justify-center font-semibold items-center gap-4 text-md w-1/1">
                      {tourDetailsData?.things_to_carry?.map((data, index) => (
                        <div className="flex items-center gap-2" key={index}>
                          <span >
                            <FaCheck size={15} color="#ff783e" />
                          </span>

                          <span >{data}</span>

                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </React.Fragment>

          {tourDetailsData?.packageDetail_id?.tourDetailOnDayBasis.map(
            (data, index) => {
              return (
                <div key={index}>
                  <React.Fragment>
                    <div className="bg-[#54b9ff] text-white font-semibold border-none w-full p-2 rounded my-4 ring-0">
                      <p>{data?.dayHeading}</p>
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="text-sm text-justify">
                      {data?.activities}
                    </div>
                  </React.Fragment>

                  <React.Fragment>
                    <div className="border rounded-md p-4 my-4 tracking-wide">
                      {data?.reporting_location && (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-md font-semibold mb-2">
                              Reporting Location
                            </div>
                            <p className="text-sm text-[#8592A6]">
                              {data?.reporting_location}
                            </p>
                          </div>

                          <div className="w-full h-px bg-gray-300 my-2"></div>
                        </>
                      )}

                      {data?.departure_time && (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-md font-semibold mb-2">
                              Deparature Time
                            </div>
                            <p className="text-sm text-[#8592A6]">
                              {data?.departure_time}
                            </p>
                          </div>

                          <div className="w-full h-px bg-gray-300 my-2"></div>
                        </>
                      )}

                      {data?.reporting_time && (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-md font-semibold mb-2">
                              Reporting Time
                            </div>
                            <p className="text-sm text-[#8592A6]">
                              {data?.reporting_time}
                            </p>
                          </div>

                          <div className="w-full h-px bg-gray-300 my-2"></div>
                        </>
                      )}

                      {data?.lunch_break && (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-md font-semibold mb-2">
                              Lunch Break
                            </div>
                            <p className="text-sm text-[#8592A6]">
                              {data?.lunch_break}
                            </p>
                          </div>

                          <div className="w-full h-px bg-gray-300 my-2"></div>
                        </>
                      )}

                      {data?.how_to_reach && (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-md w-[80vh] font-semibold mb-2">
                              How to reach
                            </div>
                            <p className="text-sm text-[#8592A6]">
                              {data?.how_to_reach}
                            </p>
                          </div>

                          <div className="w-full h-px bg-gray-300 my-2"></div>
                        </>
                      )}

                      {data?.sightSeeing.length > 0 && (
                        <>
                          <div className="flex justify-between items-center">
                            <div className="text-lg font-bold mb-2 text-[#ff783e]">
                              Sight Seeing
                            </div>
                            <p className="text-md font-semibold">
                              {data?.sightSeeing.join(", ")}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </React.Fragment>
                </div>
              );
            }
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default Details;
