import React, { useEffect, useState } from "react";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoCloseSharp } from "react-icons/io5";

const AllToursList = () => {
  document.title = 'Weekend Bhraman | Tour List';
  const [allToursList, setAllToursList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredTours, setFilteredTours] = useState([]);
  let apiUrl = process.env.REACT_APP_LIVE_URL;

  useEffect(() => {
    if (apiUrl) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiUrl}/package/getall`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setAllToursList(response?.data?.packageData);
          setFilteredTours(response?.data?.packageData); // Initialize filteredTours with all tours
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Unable to get data");
    }
  }, [apiUrl]);

  let navigate = useNavigate();

  const goToTourDetails = (id) => {
    navigate(`/tour-details/${id}`);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const newFilteredTours = allToursList.filter((tour) =>
      tour.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredTours(newFilteredTours);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    setFilteredTours(allToursList);
  };

  return (
    <React.Fragment>
      <div className="lg:px-[18%] md:px-[12%] px-[15%] m-auto mt-5">
        <form onSubmit={handleSearchSubmit}
          action=""
          className="rounded-xl border-2 border-orange-500 flex p-2">
          <input
            type="text"
            placeholder="Search tours.."
            className="border border-[#ff783e] w-[90%] px-2 py-1 rounded-l-md outline-none"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button
            type="button"
            onClick={handleClearSearch}
            style={{ display: searchInput ? 'block' : 'none' }}
            className="border px-2 border-[#ff783e] border-l-0"
          >
            <IoCloseSharp size={20} />
          </button>
          <button type="submit" className="md:w-[10%] w-[30%] rounded-r-md border-[#ff783e] bg-[#ff783e] hover:bg-[#d4531c] text-white">
            Search
          </button>
        </form>
      </div>

      <div className="flex flex-wrap justify-center items-center gap-4 mt-4">
        {
          !(allToursList.length > 0) ? (
            <>
              {[0, 1, 2, 3].map((index) => {
                return (
                  <div
                    key={index}
                    className="min-w-72 bg-gray-200 shadow-md rounded animate-pulse transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105"
                  >
                    <div className="w-full h-48 bg-gray-300 rounded-t-md"></div>
                    <div className="p-4">
                      <div className="mb-2">
                        <div className="text-lg font-semibold mb-2 bg-gray-300 h-5 w-2/3"></div>
                        <div className="flex justify-start items-center">
                          <div className="text-sm mb-2 text-gray-500 bg-gray-300 h-4 w-2/3"></div>
                        </div>
                      </div>
                      <div className="w-full h-px bg-gray-300 mb-2"></div>
                      <div className="flex justify-between items-center text-sm">
                        <div className="text-gray-500 bg-gray-300 h-4 w-1/3"></div>
                        <div className="text-[#ff783e] cursor-pointer font-semibold bg-gray-300 h-4 w-1/4"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {
                filteredTours.length > 0
                  ?
                  <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
                    {filteredTours.map((data, index) => (
                      <div
                        className="min-w-72 bg-[#F1F4F9] shadow-md rounded transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105"
                        key={index}
                      >
                        <img
                          className="w-full h-48 object-cover rounded-t-md"
                          src={data?.image_url}
                          alt="tour-img"
                        />

                        <div className="p-4">
                          <div className="">
                            <div className="text-lg w-[250px] h-[50px] break-keep font-semibold mb-2 text-[#0C2D62]">
                              {data?.name}
                            </div>
                            <div className="flex justify-start items-center">
                              <RiFlightTakeoffLine
                                size={20}
                                color="#30aadd"
                                className="mt-[-8px] mr-1"
                              />
                              <div className="text-sm mb-2 text-[#8592A6]">
                                {data?.arrival_city}
                              </div>
                            </div>
                          </div>

                          <div className="w-full h-px bg-gray-300 mb-2"></div>

                          <div className="flex justify-between items-center text-sm">
                            <div className="text-[#0C2D62]">
                              {data?.tour_duration}
                            </div>

                            <div
                              onClick={() => goToTourDetails(data?._id)}
                              className="text-[#ff783e] cursor-pointer font-semibold hover:scale-110 hover:underline ease-in-out hover:duration-300 hover:text-[#d4531c] duration-300"
                            >
                              Explore Now
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> : <div className="border w-[65%] m-auto text-center py-5 rounded-lg">
                    <div className="text-gray-400">No Result Found</div>
                  </div>
              }
            </>
          )
        }
      </div>
    </React.Fragment>
  );
};

export default AllToursList;
