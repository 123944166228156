import * as React from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import { IoVolumeLowOutline, IoCloseSharp } from "react-icons/io5";
import { useState } from "react";
import emailjs from '@emailjs/browser';
import { useRef } from "react";
import { FaThumbsUp } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoLogoWhatsapp, IoIosMail } from "react-icons/io";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted width for better responsiveness
    maxWidth: "900px", // Maximum width to prevent excessive stretching
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 2,
};

const GetInTouchModal = (props) => {
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [placeError, setPlaceError] = useState(false);
    const [questionsError, setQuestionsError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Place: "",
        Questions: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const form = useRef();

    const HandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        if (!formData.Name) {
            setNameError(true);
            setLoading(false)
        } else if (!formData.Email) {
            setEmailError(true);
            setLoading(false)
        } else if (formData.Mobile.length !== 10) {
            setMobileError(true);
            setLoading(false)
        } else if (!formData.Place) {
            setPlaceError(true);
            setLoading(false)
        } else if (!formData.Questions) {
            setQuestionsError(true);
        } else {
            try {
                console.log("Sending form...");
                const result = await emailjs.sendForm('weekend_bhraman', 'weekendbhraman_template', form.current, '5TMrjuVzjaJkSGi6w');
                console.log(result.text);
                console.log("Your message has been delivered");
                props.setOpenGetInTouchModal(false);
                handleClear()
                setLoading(false)
            } catch (error) {
                console.error("Error sending form:", error);
                setLoading(false)
            }
        }
    };


    const handleClear = () => {
        setFormData({
            Name: "",
            Email: "",
            Mobile: "",
            Place: "",
            Questions: "",
        });
        setNameError(false);
        setEmailError(false);
        setMobileError(false);
        setPlaceError(false);
        setQuestionsError(false);
    };



    return (
        <React.Fragment>
            <style jsx>
                {`
                   ::-webkit-scrollbar {
                     width: 3px;
                   }

                   ::-webkit-scrollbar-thumb {
                     background-color: #FF783D; /* Set the desired background color of the thumb */
                     border-radius: 6px;
                     border: 3px solid #; /* Set the desired border color */
                   }

                   ::-webkit-scrollbar-track {
                     background-color: #cbd5e0; /* Set the desired background color of the track */
                     border-radius: 1px;
                   }
                 `}
            </style>

            <Modal
                open={props.openGetInTouchModal}
                onClose={() => props.setOpenGetInTouchModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="absolute left-1 top-1">
                        <button className="bg-[#ff783e] hover:bg-[#d4531c] rounded-lg" onClick={() => {
                            handleClear()
                            props.setOpenGetInTouchModal(false)
                        }}><IoCloseSharp size={25} color="white" /></button>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 lg:auto-rows-fr lg:h-[70vh] h-[400px] lg:overflow-hidden w-full overflow-y-scroll">
                        <div className="bg-gray-100 p-2 lg:overflow-y-scroll">
                            <h1 className="font-medium text-[25px] text-center mb-2">Why Book With Us</h1>
                            <div className="grid grid-cols-1 gap-2">
                                <p className="bg-white  py-2 pl-2 flex items-center gap-2"> <FaThumbsUp /> Get Best Deals</p>
                                <p className="bg-white  py-2 pl-2 flex items-center gap-2"><IoCall /> Call us Now : 8178958591</p>
                                <p className="bg-white  py-2 pl-2 flex items-center gap-2"><IoLogoWhatsapp /> Whatsapp Number : 8178958591</p>
                                <p className="bg-white  py-2 pl-2 flex items-center gap-2 truncate"><IoIosMail size={18} /> Info@weekendbhraman.com</p>
                            </div>

                            <div className="grid grid-cols-1 gap-1 mt-5">
                                <div className="flex items-center gap-2 py-2">
                                    <IoVolumeLowOutline size={18} />
                                    <p>Tell us details of your holiday plan.</p>
                                </div>
                                <div className="flex items-center gap-2 py-2">
                                    <IoVolumeLowOutline size={18} />
                                    <p>Get multiple quotes from expert agents. <br /> compare & customize further.</p>
                                </div>
                                <div className="flex items-center gap-2 py-2">
                                    <IoVolumeLowOutline size={18} />
                                    <p>Select & Book Best Deal.</p>
                                </div>
                                <div className="flex items-center gap-2 py-2">
                                    <IoVolumeLowOutline size={18} />
                                    <p>Offer Deals Available On Phone & Email id.</p>
                                </div>
                                <div className="flex items-center gap-2 py-2">
                                    <IoVolumeLowOutline size={18} />
                                    <p>Get Perfect Advice From Our Tour Expert.</p>
                                </div>
                                <div className="flex items-center gap-2 py-2">
                                    <IoVolumeLowOutline size={18} />
                                    <p>100% customized tour quotes for you.</p>
                                </div>
                            </div>

                            <h1 className="text-xl mt-2">Winter Holiday Deals 2024</h1>
                        </div>
                        <div className="bg-[#699177] p-4 lg:overflow-y-scroll">
                            <h1 className="text-white text-center">Where Do You Want To Go ?</h1>
                            <div>
                                <form ref={form} onSubmit={HandleSubmit} className="grid grid-cols-1 gap-3">

                                    <div className="grid grid-cols-1 gap-1">
                                        <label htmlFor="Name" className="text-white mb-1 ml-1">Name</label>
                                        <input
                                            value={formData.Name}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setNameError(false);
                                            }}
                                            name="Name" type="text" placeholder="Name" className={nameError ? "border border-red-500 pl-3 py-2 rounded-3xl" : "border pl-3 py-2 rounded-3xl"}
                                        />

                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Email">Email</label>
                                        <input
                                            value={formData.Email}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setEmailError(false);
                                            }} name="Email" type="email" placeholder="Email" className={emailError ? "border border-red-500 pl-3 py-2 rounded-3xl" : "border pl-3 py-2 rounded-3xl"} />
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Mobile">Mobile</label>
                                        <input
                                            value={formData.Mobile}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setMobileError(false);
                                            }} name="Mobile" type="number" placeholder="Mobile" className={mobileError ? "border border-red-500 pl-3 py-2 rounded-3xl" : "border pl-3 py-2 rounded-3xl"} />
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Place">Place to visit</label>
                                        <input
                                            value={formData.Place}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setPlaceError(false);
                                            }} name="Place" type="text" placeholder="Place to visit/Query" className={placeError ? "border border-red-500 pl-3 py-2 rounded-3xl" : "border pl-3 py-2 rounded-3xl"} />
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1 " htmlFor="Questions">Questions</label>
                                        <textarea
                                            value={formData.Questions}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setQuestionsError(false);
                                            }} name="Questions" type="text" placeholder="If you have any Questions?" className={questionsError ? "border border-red-500 pl-3 py-2 rounded-3xl" : "border pl-3 py-2 rounded-3xl"} />
                                    </div>
                                    <button type="submit" className="bg-[#ff783e] hover:bg-[#d4531c] rounded-3xl p-2 mt-3 mb-3 text-white font-bold transition-all duration-400 ease-in-out">
                                        {loading ? "Loading..." : "Submit"}
                                    </button>
                                </form>
                            </div>
                            <div className="text-white text-center font-medium text-sm md:text-base flex justify-center items-center">
                                <div>
                                    <p className="flex items-center gap-2 justify-center"><IoCall /> Call us For Details: +91 8178958591</p>
                                    <p className="flex items-center gap-2 justify-center"><IoLogoWhatsapp /> Whatsapp Number: +91 8178958591</p>
                                    <p className="flex items-center gap-2 justify-center"><IoIosMail size={18} /> Info@weekendbhraman.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default GetInTouchModal;

