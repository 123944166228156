import React, { useEffect, useState } from "react";
import { SiPhonepe } from "react-icons/si";
import { SiRazorpay } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { Razorpay } from "./Razorpay";

const PaymentsForm = ({ userData, packageData, order_id }) => {
  document.title = "Weekend Bhraman | Payment Summary";

  const [isPayTotal, setIsPayTotal] = useState(true);

  const [adultsPrice, setAdultsPrice] = useState(0);

  const [childrenPrice, setChildrenPrice] = useState(0);

  const [finalPrice, setFinalPrice] = useState(0);
  const [totalMemberPrice, setTotalMemberPrice] = useState(0);
  const [gstPrice, setGstPrice] = useState(0);

  function convertPriceToNumber(priceString) {
    // Remove commas and convert to number
    const priceNumber = parseFloat(priceString?.replace(/,/g, ""));

    // Check if the conversion was successful
    if (!isNaN(priceNumber)) {
      return priceNumber;
    } else {
      console.error(`Error converting price: ${priceString}`);
      return null;
    }
  }



  // Update final price whenever package or counts change
  useEffect(() => {
    if (packageData) {
      const packagePrice = convertPriceToNumber(
        packageData?.packageCategory?.price
      );

      if (packagePrice) {
        const totalAdultsPrice = userData?.adultsCount * packagePrice;
        setAdultsPrice(totalAdultsPrice);
        const totalChildrenPrice = userData?.childrenCount * packagePrice;
        setChildrenPrice(totalChildrenPrice);

        const baseTotalPrice = totalAdultsPrice + totalChildrenPrice;
        setTotalMemberPrice(baseTotalPrice);
        const gstAmount = (5 / 100) * baseTotalPrice;
        setGstPrice(gstAmount);

        const totalPrice = baseTotalPrice + gstAmount;

        console.log("totalPrice", totalPrice);
        setFinalPrice(totalPrice);
      }
    }
  }, [packageData, userData?.adultsCount, userData?.childrenCount]);

  const handlePaymentOptionChange = (e) => {
    const selectedValue = e.target.value;
    console.log("Selected Payment Option:", selectedValue);
    setIsPayTotal(selectedValue === "payTotal");
  };

  const payLaterTotalPrice = (userData?.adultsCount + userData?.childrenCount) * 2000;
  const payLaterGst = payLaterTotalPrice * (5 / 100);

  const PriceDetails = {
    Total_price: Number(finalPrice.toFixed(2)),
    gst: Number(gstPrice.toFixed(2)),
    Total_Member_Price: totalMemberPrice,
    Total_Adult_Price: adultsPrice,
    Total_Childrent_Price: childrenPrice
  }



  return (
    <React.Fragment>
      <div class="col-span-full px-4 py-6 lg:col-span-5">
        <div class="mx-auto w-full max-w-lg">
          <h1 class="relative text-2xl font-medium w-full text-gray-700 sm:text-3xl">
            Secure Payment
            <span class="mt-2 block h-1 w-full bg-[#30aadd] mb-4"></span>
          </h1>

          <p class="text-lg font-semibold text-[#051836] text-opacity-80 mt-4">
            Choose your Payment Method
          </p>

          {/* Razor Pay */}
          <div class="flex items-center justify-center mt-4 text-opacity-80">
            <div class="container border border-[#30aadd] p-4 flex items-center rounded-md justify-between">
              <div className="flex justify-start items-center">
                <SiRazorpay color="#0d94fb" size={22} />
                <p class="text-xl font-bold ml-4 text-[#051836]">Razor Pay</p>
              </div>
              <input class="text-xl" checked type="radio" />
            </div>
          </div>

          {/* Phone Pe */}
          <div class="flex items-center justify-center mt-4">
            <div class="container border p-4 flex items-center rounded-md justify-between">
              <div className="flex justify-start items-center">
                <div class="text-[#5f259f]">
                  <SiPhonepe size={22} />
                </div>
                <p class="text-xl font-bold ml-4 text-[#5f259f] ">Phone Pe </p>
              </div>
              <input disabled class="text-xl" type="radio" />
            </div>
          </div>

          {/* Pay */}
          <div class="flex items-center justify-center mt-4">
            <div class="container border p-4 flex items-center rounded-md justify-between">
              <div className="flex justify-start items-center">
                <div class="text-opacity-50 ">
                  <FcGoogle size={22} />
                </div>
                <p class="text-xl font-bold ml-4 text-[#5c6065] ">Pay</p>
              </div>
              <input disabled class="text-xl" type="radio" />
            </div>
          </div>

          {/* Payment Options */}
          <div class="mt-20 border p-4 rounded-md flex justify-between">
            <label>
              <input
                onChange={(e) => handlePaymentOptionChange(e)}
                type="radio"
                name="paymentOption"
                value="payTotal"
                class="mr-2"
                checked={isPayTotal}
              />
              <span class="text-[#051836]">Pay Total</span>
            </label>

            <label class="ml-4">
              {" "}
              {/* Add some left margin for spacing */}
              <input
                onChange={(e) => handlePaymentOptionChange(e)}
                type="radio"
                name="paymentOption"
                value="payNow"
                class="mr-2"
              />
              <span class="text-[#051836]">Pay Later (Pay Now - min ₹2,000/person)</span>
            </label>
          </div>

          {/* Payment Summary */}
          <div class="space-y-2 mt-4 border p-4 rounded-md">
            {isPayTotal && (
              <>
                <p class="flex justify-between text-lg font-bold text-[#051836]">
                  <span>Total price:</span>
                  <span>₹ {finalPrice.toFixed(2)}</span>
                </p>
                <p class="flex justify-between text-sm font-medium text-[#051836]">
                  <span>GST (5%):</span>
                  <span>₹ {gstPrice.toFixed(2)}</span>
                </p>
                <p class="flex justify-between text-sm font-medium text-[#051836]">
                  <span>Total Member Price:</span>
                  <span>₹ {totalMemberPrice}</span>
                </p>
                <p class="flex justify-between text-sm font-medium text-[#051836]">
                  <span>Total Adult Price:</span>
                  <span>₹ {adultsPrice}</span>
                </p>
                {childrenPrice > 0 && (
                  <>
                    <p class="flex justify-between text-sm font-medium text-[#051836]">
                      <span>Total Children Price:</span>
                      <span>₹ {childrenPrice}</span>
                    </p>
                  </>
                )}
              </>
            )}

            {!isPayTotal && (
              <>
                <p class="flex justify-between text-lg font-bold text-[#051836]">
                  <span>Total price:</span>
                  <span>₹ {payLaterTotalPrice + payLaterGst}</span>
                </p>

                <p class="flex justify-between text-sm font-medium text-[#051836]">
                  <span>GST (5%):</span>
                  <span>₹ {payLaterGst}</span>
                </p>
              </>
            )}
          </div>

          {
            isPayTotal
            &&
            <Razorpay
              userData={userData}
              amount={finalPrice}
              packageData={packageData}
              PriceDetails={PriceDetails}
            />

          }

          {
            !isPayTotal
            &&
            <Razorpay
              userData={userData}
              amount={payLaterTotalPrice + payLaterGst}
              packageData={packageData}
              PriceDetails={PriceDetails}
            />
          }

          <p class="mt-5 text-center text-sm font-semibold text-gray-500">
            By placing this order you agree to the &nbsp;
            <span class="text-[#30aadd]">Terms and Conditions</span>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentsForm;
