import React from "react";
import heroImg from "../Assets/Tours List.webp";
import AllToursList from "../Components/AllToursList";

const ToursList = () => {
  document.title = 'Weekend Bhraman | Tour List';

  return (
    <React.Fragment>
      <div className="mb-4">
        <div className="flex-grow relative">
          <div className="relative">
            <img className="w-full h-auto" src={heroImg} alt="Tourimg" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
              Our Tours
            </div>
          </div>
        </div>

        <AllToursList />
      </div>
    </React.Fragment>
  );
};

export default ToursList;
