import React from 'react';
import Grp1 from '../Assets/Grp-1.png';
import Grp2 from '../Assets/Grp-2.png';
import Grp3 from '../Assets/Grp-3.png';
import Grp4 from '../Assets/Grp-4.png';
import Grp5 from '../Assets/Grp-5.png';
import Grp6 from '../Assets/Grp-6.png';
import Grp7 from '../Assets/Grp-7.png';

const HolidayCategories = () => {
    const holidaysCats = [{ id: 1, image: Grp1, }, { id: 2, image: Grp2, }, { id: 3, image: Grp3, }, { id: 4, image: Grp4, }, { id: 2, image: Grp5, }, { id: 3, image: Grp6, }, { id: 4, image: Grp7, }];

  return (
    <React.Fragment>
    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] mr-2 text-center w-full mt-6'>
        Sector we covered
    </div>

    <div className='text-[#051836] text-2xl mr-2 font-bold text-center w-full'>
        Holiday Categories
    </div>

    <div className="flex flex-wrap justify-center mt-4 mb-4">
        <div className="catagories-container flex gap-10 w-[80%] overflow-x-auto pb-4">
            {holidaysCats.map((item) => (
                <div key={item.id} className="max-w-xl min-w-44 bg-white shadow-md rounded overflow-hidden">
                    <img className="w-full h-52 object-cover" src={item.image} alt={item.title} />
                </div>
            ))}
        </div>
    </div>
</React.Fragment>
  )
}

export default HolidayCategories
