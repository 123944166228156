import React, { useState, useEffect } from "react";
import { BiSolidHandRight } from "react-icons/bi";

export const PackageCategoryDropDown = ({
  tourCategoryData,
  setSelectedPackageCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(null);

  useEffect(() => {
    if (tourCategoryData.length > 0) {
      const defaultOption = tourCategoryData[0];
      setDefaultSelection(defaultOption);
    }
  }, [tourCategoryData]);
  const setDefaultSelection = (option) => {
    setSelectedOption(`${option?.type} ${option?.seatSharing}`);
    setSelectedOptionPrice(option?.price);
    setSelectedCategory(option);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (option) => {
    setSelectedOption(`${option?.type} ${option?.seatSharing}`);
    setSelectedOptionPrice(option?.price);
    setSelectedCategory(option);
    setIsOpen(false);
  };
  setSelectedPackageCategory(selectedCategory);
  // console.log("tourCategoryData", tourCategoryData);
  return (
    <div className="relative">
      {!(tourCategoryData?.length > 0) ? (
        <>
          {[0].map((index) => {
            return (
              <div key={index} className="min-w-72 relative rounded animate-pulse transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105">
                <div className="text-lg font-semibold mb-2 bg-gray-300 h-5 w-2/3"></div>
                <div className="flex justify-start items-center">
                  <div className="text-sm mb-2 text-gray-500 bg-gray-300 h-4 w-2/3"></div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <button
            onClick={handleToggle}
            className="bg-transparent text-gray-700 font-semibold py-2 inline-flex items-center justify-end"
          >
            <BiSolidHandRight
              size={40}
              color="#b89900"
              className="animate-pulse"
            />
            <span className="mr-2 text-xl tracking-wide font-bold text-[#ff783e] ml-2">
              {selectedOption ||
                `${tourCategoryData[0]?.type} ${tourCategoryData[0]?.seatSharing}`}
            </span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </button>

          {isOpen && (
            <ul className="absolute z-10 bg-[#f7f7f7] border border-black shadow-lg overflow-hidden transition-all duration-300 ease-in-out max-h-60 w-full sm:max-w-64">
              {tourCategoryData.map((option) => (
                <li
                  key={option._id}
                  className="text-center py-1 hover:bg-[#4a66ef] cursor-pointer text-sm font-normal text-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-base"
                  onClick={() => handleSelect(option)}
                >
                  {`${option.type} ${option?.seatSharing}`}
                </li>
              ))}
            </ul>
          )}

          <div className="text-lg font-bold text-[#051836] text-end tracking-wide">
            ₹{selectedOptionPrice || tourCategoryData[0]?.price} / Person
          </div>
        </>
      )}
    </div>
  );
};
