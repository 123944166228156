import * as React from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import { IoVolumeLowOutline } from "react-icons/io5";
import { AiFillCloseSquare } from "react-icons/ai";
import { useState } from "react";
import { IoStar, IoStarOutline, IoCloseSharp } from "react-icons/io5";
import demoProfile from "../../Assets/pf.png";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted for responsiveness
    maxWidth: "900px", // Adjusted for responsiveness
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 2,
};

const FeedBackModal = (props) => {
    const [rating, setRating] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [feedbackReview, setFeedbackReview] = useState("");
    const [userPic, setUserPic] = useState("");
    const [loadings, setLoadings] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [ratingError, setRatingError] = useState(false);
    const apiUrl = process.env.REACT_APP_LIVE_URL;

    const handleClear = () => {
        setRating(0)
        setName("")
        setEmail("")
        setFeedbackReview("")
        setUserPic("")

        setNameError(false)
        setEmailError(false)
        setRatingError(false)
    }

    const handleStarClick = (index) => {
        setRating(index + 1);
        setRatingError(false);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <span
                    key={i}
                    onClick={() => handleStarClick(i)}
                    style={{ cursor: "pointer" }}
                >
                    {i < rating ? <IoStar size={28} color="#ffd700" /> : <IoStarOutline color="#ffd700" size={28} />}
                </span>
            );
        }
        return stars;
    };

    const HandlePostFeedbacks = async (e) => {
        e.preventDefault();

        setLoadings(true)

        if (!name) {
            setNameError(true)
            toast.error('Please enter a name', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoadings(false)
        } else if (!email) {
            setEmailError(true)
            toast.error('Please enter a email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoadings(false)
        } else if (rating === 0) {
            setRatingError(true)
            toast.error('Please enter a stars rating', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoadings(false)
        } else {

            const formData = new FormData();
            formData.append("file", userPic);
            formData.append("upload_preset", "WeekendBhraman");

            const response = await fetch(
                `https://api.cloudinary.com/v1_1/drijzhqfp/image/upload`,
                {
                    method: "POST",
                    body: formData,
                }
            );

            const data = await response.json();
            console.log(data.secure_url);
            let data2;
            if (data?.secure_url) {
                data2 = {
                    "name": name,
                    "email": email,
                    "feedbackReview": feedbackReview,
                    "rating": rating,
                    "userPic": data?.secure_url
                }
            } else {
                data2 = {
                    "name": name,
                    "email": email,
                    "feedbackReview": feedbackReview,
                    "rating": rating,
                    "userPic": demoProfile
                }
            }
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${apiUrl}/feedback/addFeedback`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data2,
            };

            axios
                .request(config)
                .then((response) => {
                    console.log(response.data)
                    handleClear()
                    props.setOpenFeedbackModal(false)
                    props.FetchFeedbacks()
                    setLoadings(false)
                })
                .catch((error) => {
                    console.log(error);
                    setLoadings(false)
                });
        }
    }

    return (
        <React.Fragment>
            <Modal
                open={props.openFeedbackModal}
                onClose={() => props.setOpenFeedbackModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ToastContainer />
                    <div className="absolute left-1 top-1">
                        <button className="bg-[#ff783e] hover:bg-[#d4531c] rounded-lg" onClick={() => props.setOpenFeedbackModal(false)}><IoCloseSharp size={25} color="white" /></button>
                    </div>
                    <div className="custom-scrollbar grid lg:grid-cols-2 grid-cols-1 h-[400px] lg:h-auto lg:overflow-hidden overflow-y-scroll gap-4 ">
                        <div className="bg-gray-100 p-4 tracking-wide rounded-lg lg:h-[500px] lg:overflow-y-scroll">
                            <h1 className=" font-bold mt-1">
                                WHY IS YOUR FEEDBACK IMPORTANT TO US
                            </h1>

                            <p className="text-sm mt-1">
                                At Bharat Booking Holidays, we value and consider our guests as our top-most priority.
                                Your feedback is immensely important to us as we would greatly appreciate your
                                thoughts and views regarding our hospitality services.
                            </p>

                            <div className="ml-4 mt-2 mb-4">
                                <p><b>1</b>. Your feedback can be a suggestion, compliment, complaint, or any other observation.</p>
                                <p><b>2</b>. Your suggestion will help us to improve and better our services in the future.</p>
                                <p><b>3</b>. Your compliment is a boost for our workforce, in appreciation of their hard work and dedication.</p>
                                <p><b>4</b>. Your complaint will be treated with utmost urgency to avoid any repetition.</p>
                                <p><b>5</b>. Your other feedback can be any other observation that you may want to bring to our notice.</p>
                            </div>

                            <p className="mb-4">
                                You can also reach us directly in case of immediate emergency or action. Call: 91-
                                9805063636 E-Mail : help@bharatbooking.com
                            </p>

                            <p>
                                Your comment and suggestions will allow us to improve our standards and also enable
                                us to only meet, but exceed your expectation.
                            </p>
                        </div>
                        <div className="bg-[#699177] p-4 rounded-lg lg:h-[500px] lg:overflow-y-scroll">
                            <h1 className="text-white font-bold text-[20px]">Feadback Form</h1>
                            <p className="text-gray-300 text-sm mb-4">Share your feedback with us by simply filling up the below form and
                                clicking on "Send Feedback" Option.</p>
                            <div>
                                <form action="" onSubmit={(e) => HandlePostFeedbacks(e)} className="grid grid-cols-1 gap-3">
                                    <div className="grid grid-cols-1 gap-1">
                                        <label htmlFor="Name" className="text-white mb-1 ml-1">Name</label>
                                        <input onChange={(e) => {
                                            setName(e.target.value)
                                            setNameError(false)
                                        }} value={name} name="Name" type="text" placeholder="Name" className={nameError ? "border border-red-500 pl-4 py-2 rounded-3xl" : "border pl-4 py-2 rounded-3xl"} />
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Email">Email</label>
                                        <input onChange={(e) => {
                                            setEmailError(false)
                                            setEmail(e.target.value)
                                        }} value={email} name="Email" type="email" placeholder="Email" className={emailError ? "border border-red-500 pl-4 py-2 rounded-3xl" : "border pl-4 py-2 rounded-3xl"} />
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Image">Image</label>
                                        <input onChange={(e) => setUserPic(e.target.files[0])} name="Image" type="file" placeholder="Image" className="border pl-4 py-2 rounded-3xl bg-white" />
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Questions">Additional feedback</label>
                                        <textarea onChange={(e) => setFeedbackReview(e.target.value)} value={feedbackReview} name="Questions" type="text" placeholder="If you have any Additional feedback, Please type it here...." className="border min-h-14 pl-4 py-2 rounded-3xl text-sm" />
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <label className="text-white mb-1 ml-1" htmlFor="Questions">Rate</label>
                                        <div className={ratingError ? "border border-red-500 rounded-3xl flex items-center mt-1 gap-2" : "flex items-center mt-1 gap-2"}>
                                            {renderStars()}
                                        </div>
                                    </div>
                                    <button type="submit" className="bg-[#ff783e] hover:bg-[#d4531c] rounded-3xl p-2 mt-3 mb-3 text-white font-bold transition-all duration-400 ease-in-out">
                                        {loadings ? <div role="status">
                                            <svg aria-hidden="true" class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span class="sr-only">Loading...</span>
                                        </div> : "Submit"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment >
    );
};

export default FeedBackModal;
