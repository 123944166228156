import React from 'react';
import CommingSoon from '../Components/CommingSoon';

const Bus = () => {
    return (
        <React.Fragment>
            <CommingSoon />
        </React.Fragment>
    )
}

export default Bus
