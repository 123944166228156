import React from 'react';
import { FaCheck } from "react-icons/fa6";

const Inclusion = ({ tourDetailsData }) => {

  console.log(tourDetailsData.inclusion.here_is_our_exclusion)

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="border rounded-md p-4 mb-4">
          <div className="text-md font-semibold underline">Here is our Inclusion :</div>

          {
            tourDetailsData?.inclusion.here_is_our_inclusion.map((ele) =>
              <div className='flex justify-start items-center mt-2'>
                <div>
                  <FaCheck size={15} color='#ff783e' />
                </div>

                <p className="text-sm text-[#8592A6] ml-2">{ele}</p>
              </div>
            )
          }
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="border rounded-md p-4 mb-4">
          <div className="text-md font-semibold underline">Here is our Exclusion</div>
          {
            tourDetailsData?.inclusion.here_is_our_exclusion.map((ele) =>
              <div className='flex justify-start items-center mt-2'>
                <div>
                  <FaCheck size={15} color='#ff783e' />
                </div>

                <p className="text-sm text-[#8592A6] ml-2">{ele}</p>
              </div>
            )
          }
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default Inclusion
