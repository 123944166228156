import React from 'react'
import { GoDotFill } from 'react-icons/go'
import termAndConditions from "../Assets/termAndConditions.png"



export default function TermAndConditions() {

    return (
        <div >
            <React.Fragment>
                <div className="flex-grow relative">
                    <div className="relative">
                        <img className="w-full h-auto" src={termAndConditions} alt="Tourimg" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
                            Terms & Conditions
                        </div>
                    </div>
                </div>
            </React.Fragment>


            <React.Fragment>

                <div className="container mx-auto p-8">
                    <div>
                        <div className='flex items-center'>
                            <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start mb-4'>
                                Who are we..
                            </div>
                        </div>

                        <p className="text-[#051836] leading-relaxed text-justify">
                            The website www.weekendbhraman.com (the “Website”) is an Internet-based portal owned and Operated by Weekend Bhraman Tours Planner with its Registered Office A-37 Sachdeva Complex, Madhu Vihar I.P Ext. New Delhi-92. When you access, browse or use this website, you accept, without limitation or qualification, the terms and conditions set forth herein. When you access any of the subsidiary websites (whether belonging to an ‘associate’ of Company or otherwise) through this website, then such subsidiary websites may have their own terms and conditions, which are specific to such subsidiary websites.
                        </p>
                        <p className="text-[#051836] leading-relaxed text-justify">
                            These Terms and Conditions of use and any additional terms posted on this website together constitute the entire agreement between the Company and you with respect to your use of this particular website.
                        </p>
                    </div>

                    <div className="mt-8">
                        <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-2xl font-bold text-start w-full mb-4'>
                            General Terms of use & Service
                        </div>

                        <p className="text-[#051836] leading-relaxed text-justify">
                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Hotel can be changed as per their availability.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Cab wouldn’t be driven after 8:00 PM, in case required then extra charge will be paid to driver.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Extra stay wouldn’t be booked at any reason after departure.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >A.C wouldn’t be working after Haridwar. In case guests want to turn it on, this will be chargeable.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Guest will be responsible for any losses i.e. luggage, money, etc.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Advance amount will be paid for Helicopter booking and @1000/ per ticket will be charged.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >It will be the guest’s own responsibility to transport the luggage to and from the vehicle.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >No breakfast will be provided till 5:00 AM.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >In case of any technical issue with the vehicle, the guest needs to cooperate until the issue is resolved.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Vehicle can be arranged in case of a big issue in the vehicle, and it will take 5-6 hours.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Always follow the timing.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Payment will be acceptable only with the mentioned details or by cash.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Flexible Payment Options.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >25% advance amount for Booking.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >50% on the Day of arrival at Delhi/Haridwar.</p>
                            </div>

                            <div className='flex gap-2 justify-start items-center mt-2'>
                                <div>
                                    <GoDotFill size={15} color='#ff783e' />
                                </div>
                                <p >Please note that your full payment is necessary to pay at Pipalkoti.</p>
                            </div>
                        </p>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}
