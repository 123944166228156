import React, { useEffect, useState } from 'react';
import heroImg from "../Assets/feedback.webp";
import demoProfile from "../Assets/pf.png";
import FeedBackModal from '../Components/Modals/FeedBackModal';
import axios from 'axios';

const FeedBack = () => {
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [feedBacks, setFeedBacks] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const apiUrl = process.env.REACT_APP_LIVE_URL;
    const StarRating = ({ initialRating }) => {
        return (
            <div>
                {[1, 2, 3, 4, 5].map((index) => (
                    <span
                        key={index}
                        className={`text-xl ${index <= initialRating ? 'text-yellow-500' : 'text-gray-300'
                            }`}
                    >
                        ★
                    </span>
                ))}
            </div>
        );
    };

    const FetchFeedbacks = () => {
        setIsLoading(true)
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${apiUrl}/feedback/allFeedback`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                // console.log(response.data.allFeedback)
                setFeedBacks(response?.data?.allFeedback)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
    }

    useEffect(() => {
        FetchFeedbacks()
    }, [])



    return (
        <React.Fragment>
            <FeedBackModal
                openFeedbackModal={openFeedbackModal}
                setOpenFeedbackModal={setOpenFeedbackModal}
                FetchFeedbacks={FetchFeedbacks}
            />
            <React.Fragment>
                <div className="flex-grow relative">
                    <div className="relative">
                        <img className="w-full h-auto" src={heroImg} alt="Tourimg" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
                            Our Feedbacks
                        </div>
                    </div>
                </div>
            </React.Fragment>
            <React.Fragment>
                <div className='md:flex lg:justify-end md:justify-center md:px-20 px-5  mt-5'>
                    <div className='md:flex  items-center gap-2 '>
                        <h1 className='font-medium tracking-wider'>If you have any suggetions or feedback? <button onClick={() => setOpenFeedbackModal(true)} className='tracking-wider font-medium hover:underline text-orange-500'>FeedBack</button> </h1>
                    </div>
                </div>
            </React.Fragment>
            <React.Fragment>
                <div className="flex flex-wrap justify-center items-center gap-4 mt-2 mb-4 ">
                    {isLoading ?
                        [0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                            <div key={index} className="min-w-72 text-center bg-gray-200 shadow-md rounded animate-pulse transition-transform transform-gpu ease-in-out hover:duration-300 hover:scale-105 mt-3 mb-3 p-4">
                                <div className='flex justify-center items-center'>
                                    <div className="min-w-16 min-h-16 max-w-16 max-h-16 rounded-full mb-2 border bg-gray-300" ></div>
                                </div>
                                <div className="bg-gray-300 h-5 mb-2 w-[50%] m-auto"></div>
                                <div className="bg-gray-300 h-5 mb-2 w-[70%] m-auto"></div>
                                <StarRating />
                                <div className={`text-[#8592A6] text-xs text-center tracking-wide `}>
                                    <div className="bg-gray-300 h-5 mb-2 w-[70%] m-auto"></div>
                                    <div className="bg-gray-300 h-5 mb-2 w-[70%] m-auto"></div>
                                    <div className="bg-gray-300 h-5 mb-2 w-[70%] m-auto"></div>
                                </div>
                            </div>
                        ))

                        :
                        feedBacks?.map((item, i) => (
                            <div key={i} className="bg-[#EBEBEB] min-h-72 max-h-72 min-w-52 max-w-52 p-4 m-4 flex flex-col items-center justify-center rounded-md">
                                <img src={
                                    item?.userPic ||
                                    demoProfile
                                }
                                    alt='img'
                                    className="min-w-16 min-h-16 max-w-16 max-h-16 rounded-full mb-2" />

                                <div className="text-[#0C2D62] text-sm font-bold text-center tracking-wide">
                                    {item?.name}
                                </div>

                                <div className="text-[#0C2D62] text-xs text-center tracking-wide">
                                    {item?.email}
                                </div>

                                <StarRating initialRating={item?.rating} />

                                <div className={`text-[#8592A6] text-xs text-center tracking-wide ${item?.feedbackReview && item?.feedbackReview.length > 50 ? 'max-h-[7rem] overflow-y-scroll' : 'h-[auto]'}`}>
                                    {item?.feedbackReview || ""}
                                </div>
                            </div>
                        ))}
                </div>
            </React.Fragment>
        </React.Fragment>
    )
}

export default FeedBack
