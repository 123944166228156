import { CounterField } from "./CounterField";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TourBookForm = () => {
  const [adultsCount, setAdultsCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [expectedDeparatureCity, setExpectedDeparatureCity] = useState(0);
  const [departureDate, setDepartureDate] = useState("");


  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [expectedDepartureCityError, setExpectedDepartureCityError] = useState(false);
  const [departureDateError, setDepartureDateError] = useState(false);



  const navigate = useNavigate();

  const handleAdultsIncrement = () => {
    setAdultsCount(adultsCount + 1);
    const existingDataString = localStorage.getItem("userData");
    const existingData = JSON.parse(existingDataString) || {};
    existingData.adultsCount = adultsCount;
    const updatedDataString = JSON.stringify(existingData);
    localStorage.setItem("userData", updatedDataString);
  };
  const handleAdultsDecrement = () => {
    if (adultsCount > 0) {
      setAdultsCount(adultsCount - 1);
      const existingDataString = localStorage.getItem("userData");
      const existingData = JSON.parse(existingDataString) || {};
      existingData.adultsCount = adultsCount;
      const updatedDataString = JSON.stringify(existingData);
      localStorage.setItem("userData", updatedDataString);
    }
  };
  const handleChildrenIncrement = () => {
    setChildrenCount(childrenCount + 1);
    console.log("Children Count:", childrenCount + 1);
    const existingDataString = localStorage.getItem("userData");
    const existingData = JSON.parse(existingDataString) || {};
    existingData.childrenCount = childrenCount;
    const updatedDataString = JSON.stringify(existingData);
    localStorage.setItem("userData", updatedDataString);
  };
  const handleChildrenDecrement = () => {
    if (childrenCount > 0) {
      setChildrenCount(childrenCount - 1);
      const existingDataString = localStorage.getItem("userData");
      const existingData = JSON.parse(existingDataString) || {};
      existingData.childrenCount = childrenCount;
      const updatedDataString = JSON.stringify(existingData);
      localStorage.setItem("userData", updatedDataString);
    }
  };
  const handleUserForm = async (e) => {
    e.preventDefault();
    localStorage.setItem(
      "userData",
      JSON.stringify({
        name: name,
        email: email,
        number: number,
        expected_deparature_city: expectedDeparatureCity,
        departureDate: departureDate,
        adultsCount: adultsCount,
        childrenCount: childrenCount,
      })
    );


    if (name !== "" && email !== "" && number !== "" && expectedDeparatureCity !== "" && adultsCount !== "" && departureDate !== "") {
      navigate("/paymentSummary");
    }

    if (!name) {
      setNameError(true)
    } else {
      setNameError(false)
    }
    if (!email) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
    if (!number) {
      setNumberError(true)
    } else {
      setNumberError(false)
    }
    if (!expectedDeparatureCity) {
      setExpectedDepartureCityError(true)
    } else {
      setExpectedDepartureCityError(false)
    }

    if (!departureDate) {
      setDepartureDateError(true)
    } else {
      setDepartureDateError(false)
    }

    if (!adultsCount) {
      alert("Please choose adults count")
    }

  };

  return (
    <React.Fragment>
      <div className="md:w-[40%] w-[100%] p-4">
        <div>
          <div className="bg-[#EBEBEB] rounded-md p-4">
            <h2 className="text-lg font-bold text-[#051836] underline mb-2 text-center">
              Book a Tour
            </h2>

            <div>
              <div className="mb-4 ">
                <label
                  htmlFor="name"
                  className="block text-sm font-semibold mb-1"
                >
                  Name
                </label>
                <input
                  onChange={(e) => {
                    setName(e?.target?.value);
                  }}
                  type="text"
                  id="name"
                  name="name"
                  className={`${nameError ? "border border-red-500" : ""} w-full p-2 border border-gray-300 outline-none focus:ring-1 ring-blue-600 rounded`}
                  placeholder="Enter your name"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold mb-1"
                >
                  Email
                </label>
                <input
                  onChange={(e) => {
                    setEmail(e?.target?.value);
                  }}
                  type="email"
                  id="email"
                  name="email"
                  className={`${emailError ? "border border-red-500" : ""} w-full p-2 border border-gray-300 outline-none focus:ring-1 ring-blue-600 rounded`}
                  placeholder="Enter your email"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-semibold mb-1"
                >
                  Phone
                </label>
                <input
                  onChange={(e) => {
                    setNumber(e?.target?.value);
                  }}
                  type="text"
                  id="phone"
                  name="phone"
                  className={`${numberError ? "border border-red-500" : ""} w-full p-2 border border-gray-300 outline-none focus:ring-1 ring-blue-600 rounded`}
                  placeholder="Enter your number"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="city"
                  className="block text-sm font-semibold mb-1"
                >
                  Departure City
                </label>
                <input
                  onChange={(e) => {
                    setExpectedDeparatureCity(e?.target?.value);
                  }}
                  type="text"
                  id="city"
                  name="city"
                  className={`${expectedDepartureCityError ? "border border-red-500" : ""} w-full p-2 border border-gray-300 outline-none focus:ring-1 ring-blue-600 rounded`}
                  placeholder="Enter your departure city"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="departureDate"
                  className="block text-sm font-semibold mb-1"
                >
                  Departure Date
                </label>
                <input
                  onChange={(e) => {
                    setDepartureDate(e?.target?.value);
                  }}
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  className={`${departureDateError ? "border border-red-500" : ""} w-full p-2 border border-gray-300 outline-none focus:ring-1 ring-blue-600 rounded`}
                  placeholder="Enter your departure date"
                />
              </div>

              <div className="flex justify-between items-center mb-4">
                <div className="text-md font-semibold mb-2">No. of Adults</div>
                <CounterField
                  label="Adults"
                  count={adultsCount}
                  onIncrement={handleAdultsIncrement}
                  onDecrement={handleAdultsDecrement}
                />
              </div>

              <div className="flex justify-between items-center mb-4">
                <div className="text-md font-semibold mb-2">
                  No. of children's
                </div>
                <CounterField
                  label="Children"
                  count={childrenCount}
                  onIncrement={handleChildrenIncrement}
                  onDecrement={handleChildrenDecrement}
                />
              </div>
              <button
                onClick={(e) => handleUserForm(e)}
                type="button"
                className="w-full px-6 py-2 flex content-center items-center justify-center text-lg text-white bg-[#ff783e] hover:bg-[#d4531c] transition ease-in-out hover:duration-300 rounded-full shadow-lg shadow-[#ffdcc7]"
              >
                Book now
              </button>
            </div>
          </div>
        </div>

        <div className="bg-[#EBEBEB] rounded-md p-4 mt-4 text-center">
          <h2 className="text-lg font-bold text-[#051836] underline mb-2 ">
            Any Query ! Call Us.
          </h2>

          <div className="text-lg font-bold">24*7 Services</div>
          <div className="text-lg font-bold text-[#8592A6]">
            +91 8178958591, +91 9310366002
          </div>

          <div className="text-lg font-bold mt-4">Tollfree No.</div>
          <div className="text-lg font-bold text-[#8592A6] mb-4">
            1800-532-3636, 1800-212-0136
          </div>

          <div className="text-lg font-bold">Mail Us</div>
          <div className="text-lg font-bold text-[#8592A6]">
            Info@weekendbhraman.com
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TourBookForm;
