import React from 'react';
import { GoDotFill } from "react-icons/go";

const TAndC = ({ tourDetailsData }) => {
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="border rounded-md p-4 mb-4">
          <div className="text-md font-semibold underline">Terms & Conditions :</div>

          {
            tourDetailsData?.t_and_c.term_and_conditions.map((ele) =>
              <div className='flex justify-start items-center mt-2'>
                <div>
                  <GoDotFill size={15} color='#ff783e' />
                </div>

                <p className="text-sm text-[#8592A6] ml-2">{ele}</p>
              </div>
            )
          }
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="border rounded-md p-4 mb-4">
          <div className="text-md font-semibold underline">Cancelation Policy :</div>


          {
            tourDetailsData?.t_and_c.cancellation_policy.map((ele) =>
              <div className='flex justify-start items-center mt-2'>
                <div>
                  <GoDotFill size={15} color='#ff783e' />
                </div>

                <p className="text-sm text-[#8592A6] ml-2">{ele}</p>
              </div>
            )
          }
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="border rounded-md p-4 mb-4">
          <div className="text-md font-semibold underline">Payments Terms and Conditions</div>

          {
            tourDetailsData?.t_and_c.payment.map((ele) =>
              <div className='flex justify-start items-center mt-2'>
                <div>
                  <GoDotFill size={15} color='#ff783e' />
                </div>

                <p className="text-sm text-[#8592A6] ml-2">{ele}</p>
              </div>
            )
          }
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default TAndC
