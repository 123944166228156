import React from 'react';
import nepal from '../Assets/nepal.png';
import bhutan from '../Assets/bhutan.png';

const InternationalToursPackages = () => {
  return (
    <React.Fragment>
    <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] mr-2 text-center w-full mt-6'>
        Sector we covered
    </div>

    <div className='text-[#051836] text-2xl mr-2 font-bold text-center w-full'>
        International Tours
    </div>

    <div className='w-full flex flex-wrap justify-center items-center gap-4 my-4'>
        <img
            className="w-2/6 h-auto object-cover rounded-t-md"
            src={nepal}
            alt='img' />

        <img
            className="w-2/6 h-auto object-cover rounded-t-md"
            src={bhutan}
            alt='img' />
    </div>
</React.Fragment>

  )
}

export default InternationalToursPackages
