/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import './LowerHeader.css';
import { GrLocation } from "react-icons/gr";
import { CgMail } from "react-icons/cg";

const LowerHeader = () => {

    return (
        <React.Fragment>
            <nav className="bg-black px-4 pb-1 pt-2 shadow-xl">
                <marquee direction="left" scrollamount="4" behavior="scroll">
                    <div className="flex items-center">

                        <div className='text-white text-sm tracking-wider'>
                            Weekend Bhraman Tour Planner
                        </div>

                        <div className='text-white text-sm tracking-wider mx-8'>
                            | |
                        </div>

                        <div>
                            <GrLocation size={22} color='#30aadd' />
                        </div>
                        <div className='text-white text-sm tracking-wider ml-2'>
                            A-37 Sachdeva Complex Madhu Vihar I.P Ext. New Delhi-92
                        </div>

                        <div className='text-white text-sm tracking-wider mx-8'>
                            | |
                        </div>

                        <div className='text-white text-sm tracking-wider'>
                            Weekend Bhraman Tour Planner
                        </div>

                        <div className='text-white text-sm tracking-wider mx-8'>
                            | |
                        </div>

                        <div>
                            <GrLocation size={22} color='#30aadd' />
                        </div>
                        <div className='text-white text-sm tracking-wider ml-2'>
                            A-37 Sachdeva Complex Madhu Vihar I.P Ext. New Delhi-92
                        </div>

                        <div className='text-white text-sm tracking-wider mx-8'>
                            | |
                        </div>

                        <div>
                            <CgMail size={22} color='#30aadd' />
                        </div>
                        <div className='text-white text-sm tracking-wider ml-2'>
                            Info@weekendbhraman.com
                        </div>

                        <div className='text-white text-sm tracking-wider mx-8'>
                            | |
                        </div>

                        <div className='text-[#ff783e] text-sm font-semibold tracking-wider' style={{ fontFamily: 'cursive' }}>
                            Thanks for visiting..
                        </div>
                    </div>
                </marquee>
            </nav>
        </React.Fragment>
    )
}

export default LowerHeader
