import React from 'react';
import lgs from '../Assets/lgs.png'
import world from '../Assets/world.png'

const ExperienceCard = () => {
  return (
    <React.Fragment>
                <div className="flex flex-col md:flex-row w-full justify-center items-center bg-[#f7fffe] py-8 px-8 md:px-20 text-white">
                    <div className="md:w-1/2 hidden md:block">
                        <img
                            className="w-[85%] h-auto rounded-lg"
                            src={lgs}
                            alt="luggage-pic"
                        />
                    </div>

                    <div className="flex w-full md:w-1/2">
                        <div className="">
                            <div style={{ fontFamily: 'cursive' }} className='text-[#ff783e] text-xl w-full tracking-wide'>
                                Welcome to Tours & Travel
                            </div>

                            <div className='text-[#051836] text-4xl font-bold w-full mt-2 tracking-wide leading-normal'>
                                Life Begins at the end of your Comfort Zone
                            </div>

                            <div className='text-[#8592A6] mr-2 mt-4 w-full tracking-wide text-justify'>
                                We would love to help you realize your travel dreams, to start a new. Take the first steps towards making your dream a reality, We would love to help you realize your travel dreams, to start a new. Take the first steps towards making your dream a reality
                            </div>

                            <div className='flex items-center mt-4'>
                                <div>
                                    <div className='text-[#051836] text-xl font-bold w-full tracking-wide'>
                                        Best Travel Agents
                                    </div>

                                    <div className='text-[#8592A6] mt-2 w-full tracking-wide'>
                                        We've 10 years experience of organizing worldwide travel.
                                    </div>
                                </div>

                                <div>
                                    <div className='text-[#051836] text-xl font-bold w-full tracking-wide'>
                                        Travel Guidelines
                                    </div>

                                    <div className='text-[#8592A6] mt-2 w-full tracking-wide'>
                                        Prepare to be flexible during your trip as restrictions and policies
                                    </div>
                                </div>
                            </div>

                            <div className="w-full h-px bg-gray-300 mt-4"></div>

                            <div className='flex flex-col md:flex-row items-center mt-4'>
                                <div className="flex justify-center md:justify-start md:w-[20%] w-full hover:animate-bounce">
                                    <img
                                        className="h-20 w-20"
                                        src={world}
                                        alt="world-pic"
                                    />
                                </div>

                                <div className=' md:w-[80%] w-full mt-2 md:mt-0'>
                                    <div className='text-xl font-semibold w-full tracking-wide text-justify'>
                                        <span className='text-[#051836] '>We Have</span>
                                        &nbsp;<span className='text-[#ff783e]' style={{ fontFamily: 'cursive' }}>10+ Years</span>
                                        &nbsp;<span className='text-[#051836] '>of Experience for give you better Tour & Travel Package</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
  )
}

export default ExperienceCard
