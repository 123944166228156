import React from "react";

export const CounterField = ({ label, count, onIncrement, onDecrement }) => {
  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={onDecrement}
        className="w-10 h-10 flex justify-center items-center text-xl rounded-md font-bold text-white bg-[#54b9ff] hover:bg-[#398bc5] transition ease-in-out hover:duration-300 shadow-lg"
      >
        -
      </button>

      <div className="text-xl font-semibold w-20 h-10 flex justify-center items-center">
        {count}
      </div>

      <button
        onClick={onIncrement}
        className="w-10 h-10 flex justify-center items-center text-xl rounded-md font-bold text-white bg-[#54b9ff] hover:bg-[#398bc5] transition ease-in-out hover:duration-300 shadow-lg"
      >
        +
      </button>
    </div>
  );
};
