import React from "react";
import UpperHeader from "../Components/UpperHeader";
import LowerHeader from "../Components/LowerHeader";
import MiddleHeader from "../Components/MiddleHeader";

const Navbar = () => {
  return (
    <React.Fragment>
      <div className="shadow-md">
        <input
          className="h-[0vh] w-[0vh] hidden bg-transparent border-0 ring-0 outline-none"
          autofocus
        ></input>
        <UpperHeader />
        <MiddleHeader />
        <LowerHeader />
      </div>
    </React.Fragment>
  );
};

export default Navbar;
