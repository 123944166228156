import React from 'react';
import commingSoonImg from '../Assets/commingSoon.gif'

const CommingSoon = () => {
  return (
    <React.Fragment>
    <div className="flex items-center justify-center bg-[#30aadd] p-2">
        <img
            src={commingSoonImg}
            alt="Comming-soon-img"
            className="object-cover object-center h-full w-full"
        />
    </div>
</React.Fragment>
  )
}

export default CommingSoon
